@import "smart-grid";
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

.ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

* {
  .ubuntu();
}

.red {
  color: #FB0114;
}

.bordo {
  color: #9B331C
}

.blue {
  color: #032BFF;
}

.green {
  color: #28A745;
}

section {
  margin-bottom: 50px;
}

.logo {
  display: inline-block;
  width: 130px;
  height: 76px;
  background: url(../img/logo.png);
  margin-right: 0;
}

.active a {
  .ubuntu();
  font-weight: 700;
}

// Форма
.form_block {
  margin-top: 23px;
  position: relative;

  form {
    .form-group {
      margin-bottom: 40px;

      .xs-block({ margin-bottom: 35px; });
    }
  }

  h5 {
    font-size: 16px;
    line-height: 26px;
  }

  .name_vuz {
    color: #757575;
  }
}

.head_input {
  margin-bottom: 12px;
  .ubuntu();
  font-weight: 700;

  .sm-block({ border-left: 8px solid #9b331c; padding-left: 10px; });
  .xs-block({ font-size: 24px; })
}

.first_page {
  margin-bottom: 60px;
}

.text_input,
.btn-input {
  width: 255px;
  height: 40px;
  border-radius: 0;
}

.text_input {

  &:hover {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(0, 123, 255, .25);
  }
}

.btn-input {
  line-height: 16px;
  margin-top: 17px;
  font-size: 20px;
  width: 250px;
  border-radius: 6px;
  height: 48px;
  background: #343A40;
  color: #fff;

  &:active, &:focus {
    background: #343A40;
    color: #fff;
    border: none !important;
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #343A40 !important;
    color: #fff;
  }
}

// Таблица
.tableall {
  width: 100%;
  min-height: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
}

.width100 {
  width: 100%;
  min-height: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
}

th {
  font-size: 16px;
  .ubuntu();
  font-weight: 700;
}

.width100 {

  tr {
    vertical-align: middle;
    td {
      text-align: center;
      font-size: 13px;
    }
  }

}

.thead-light {
  tr {
    height: 48px;
    .md-block({ font-size: 12px; });
    th {

      vertical-align: middle !important;
      //padding-bottom: 12px !important; // Получается разрыв таблицы
    }
  }
}

.header_th {
  text-align: center;
}

.bg_transparent {
  background: transparent;
  vertical-align: middle !important;
}

tr {
  td {
    text-align: center;
    font-size: 14px;
    .md-block({ font-size: 12px; })
  }
  th {
    font-size: 12px;
    text-align: center;
    .md-block({ font-size: 12px; })
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #dee2e6;
  .md-block({ font-size: 12px; })
}

// Табл
.point {
  margin-top: 36px;
  margin-bottom: 10px;

  tr {
    td {
      text-align: left;

      a {
        font-size: 22px;
        line-height: 0;
        color: #000;
        .md-block({ font-size: 12px; })
      }
      .link_command {
        font-size: 16px;
        color: #000;
      }
      .link_fuck {
        font-size: 16px;
        color: #000;
      }
    }
    th {
      text-align: left;
      .md-block({ font-size: 12px; })
    }
  }
}

#kriterii {
  background: #1e5380;
  position: relative;
  z-index: 5;
}

.breadcrumb {
  background: transparent;

  .breadcrumb-item {
    font-size: 14px;
    font-weight: 700;

    .xs-block({ margin-bottom: 10px; });

    &::before {
      content: '';
    }

    &::after {
      content: '-';
      margin-left: 15px;
    }

    &:last-child {
      &::after {
        content: '';
        margin-left: 0;
      }
    }

    a {
      font-size: 14px;
      font-weight: 700;

      .sm-block({ font-size: 14px; });
    }
  }

  li[aria-current="page"] {
    .xs-block({ padding-left: 0; margin-left: -8px; });
  }
}

#btn_ready {
  display: inline-block;
  width: 250px;
  height: 47px;
  font-size: 20px;
  color: #fff;
  background-color: #343a40;
  border-radius: 5px;
  border: 1px solid #343a40;
}

.wrap_btn_res {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  font-weight: 700;
  align-items: baseline;

  .xs-block({ margin-top: -18px; });

  .btn {
    padding: 5px 35px;
    .ubuntu();
  }

  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }

  .btn_ready {
    display: inline-block;
    width: 250px;
    height: 47px;
    font-size: 20px;
    color: #fff;
    background-color: #343a40;
    border-radius: 5px;
    border: 1px solid #343a40;
  }

  .check_green {
    display: inline-block;
    width: 19px;
    height: 15px;
    background: url(../img/check.svg) center center/cover no-repeat;
    margin-right: 10px;
    margin-left: 45px;
    margin-top: 31px;

    .xs-block({ margin-left: 0; })
  }
}

.input_new_point {
  width: 155px;
  height: 30px;
  text-indent: 5px;
}

.not_active {
  opacity: .5;
}

.danger {
  display: block;
  width: 100%;
  font-size: 16px;
  margin-top: 16px;
}

.nav-item {
  .xs-block({ text-align: right; });
}

// Пункты меню
.navbar-collapse {
  .md-block({ background: #9B331C; margin: 0 !important; });
}

.navbar-nav {
  margin-left: 37px;
  margin-top: 11px;

  .md-block({ background: #9B331C; margin: 19px 0 0 0 !important; });

  li {
    margin-right: 40px;

    .md-block({ margin-right: 0; });

    a.nav-link {

      .md-block({ color: #fff !important; margin-right: 12px; });
    }

    // Активная ссылка меню
    .active_link {
      display: inline-block;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 8px;
        background: #fff;
        margin-right: 12px;
        margin-bottom: 3px;
      }
    }
  }

  .nav-item {
    //margin-right: 53px;
    text-align: right;

    &:first-child {
      margin-right: 40px;

      .md-block({ margin-right: 0; })
    }

    a.nav-link {
      font-size: 20px;
    }
  }
}

// Мобильное меню
.navbar-toggler-icon {
  display: inline-block;
  width: 18px;
  height: 12px;
  background-image: url(../img/mobile_menu.svg) !important;
  border: none !important;

  &::before {
    margin-top: 19px;
    content: 'Меню';
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-left: -16px;
  }
}

.navbar-toggler {
  border: none !important;
  &:hover, &:active, &:focus {
    border: none !important;
    outline: none !important;
  }
}

// Меню
.navbar-collapse {
  margin-left: 64px;
}

.num_tab {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  text-align: center;
}

#table_block {
  display: none;

  .tab_block {
    display: block;
    width: 91%;
    overflow-x: auto;
    position: fixed;
    top: 0;
    background: #e9ecef;
    // margin-left: 15px;
    margin-right: 15px;

    table {
      thead {
        tr {
          th {
            color: #333333;
            .md-block({ font-size: 12px; })
          }

          .num_first {
            min-width: 51px;
          }
          .command {
            min-width: 106px;
          }
          .value {
            min-width: 69px;
          }
          .fine {
            min-width: 77px;
          }
          .total_point {
            min-width: 73px;
          }
          .place {
            min-width: 50px;
          }
          .criterion {
            min-width: 459px;
            background: #dee2e4 !important;
            border-color: #c0c4c6 !important;
            padding: 0 !important;
            border-bottom: none;

            table {
              background: #dee2e4 !important;
              border-color: #c0c4c6 !important;

              tr {
                background: #dee2e4 !important;
                border-color: #c0c4c6 !important;

                th {
                  background: #dee2e4 !important;
                  border-color: #c0c4c6 !important;
                }

                .k1 {
                  min-width: 69px;
                }
                .k2 {
                  min-width: 76px;
                }
                .k3 {
                  max-width: 137px;
                }
                .k4 {
                  min-width: 147px;
                }
                .k5 {
                  min-width: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.criterion_glob {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
  padding: 0 !important;

  table {
    background: #dee2e4 !important;
    border-color: #c0c4c6 !important;

    tr {
      background: #dee2e4 !important;
      border-color: #c0c4c6 !important;

      th {
        background: #dee2e4 !important;
        border-color: #c0c4c6 !important;
      }
    }
  }
}

// Мобильное меню
#menu_nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: none;
  background: #9B331C;
  z-index: 10;

  .wrap_first_row {
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    overflow: hidden;

    .logo {
      display: inline-block;
      margin-left: 16px;
      margin-top: 8px;
    }

    #hide_menu {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../img/close.svg) center center/cover no-repeat;
      margin-right: 16px;
      margin-top: 8px;
    }
  }
}

tbody {
  tr {
    &:first-child {
      td {
        padding-top: 15px;
      }
    }

    td {
      vertical-align: middle;
      a {
        text-decoration: underline;
      }
    }
  }
}

.grey {
  color: #757575;
}

.table_block_origin {
  margin-top: 50px;
}

.link_vuz {
  color: #000;
}