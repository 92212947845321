@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Ubuntu Light'), local('Ubuntu-Light'), url(https://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoC1CzjsGyI.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url(https://fonts.gstatic.com/s/ubuntu/v14/4iCs6KVjbNBYlgoKfw7z.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(https://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoCjC3jsGyI.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(https://fonts.gstatic.com/s/ubuntu/v14/4iCv6KVjbNBYlgoCxCvjsGyI.ttf) format('truetype');
}

.ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

* {
  font-family: 'Ubuntu', sans-serif;
}

.red {
  color: #FB0114;
}

.bordo {
  color: #9B331C;
}

.blue {
  color: #032BFF;
}

.green {
  color: #28A745;
}

section {
  margin-bottom: 50px;
}

.logo {
  display: inline-block;
  width: 130px;
  height: 76px;
  background: url(../img/logo.png);
  margin-right: 0;
}

.active a {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}

.form_block {
  margin-top: 23px;
  position: relative;
}

.form_block form .form-group {
  margin-bottom: 40px;
}

.form_block h5 {
  font-size: 16px;
  line-height: 26px;
}

.form_block .name_vuz {
  color: #757575;
}

.head_input {
  margin-bottom: 12px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}

.first_page {
  margin-bottom: 60px;
}

.text_input,
.btn-input {
  width: 255px;
  height: 40px;
  border-radius: 0;
}

.text_input:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.btn-input {
  line-height: 16px;
  margin-top: 17px;
  font-size: 20px;
  width: 250px;
  border-radius: 6px;
  height: 48px;
  background: #343A40;
  color: #fff;
}

.btn-input:active,
.btn-input:focus {
  background: #343A40;
  color: #fff;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.btn-input:hover {
  background: #343A40 !important;
  color: #fff;
}

.tableall {
  width: 100%;
  min-height: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
}

.width100 {
  width: 100%;
  min-height: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
}

th {
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}

.width100 tr {
  vertical-align: middle;
}

.width100 tr td {
  text-align: center;
  font-size: 13px;
}

.thead-light tr {
  height: 48px;
}

.thead-light tr th {
  vertical-align: middle !important;
}

.header_th {
  text-align: center;
}

.bg_transparent {
  background: transparent;
  vertical-align: middle !important;
}

tr td {
  text-align: center;
  font-size: 14px;
}

tr th {
  font-size: 12px;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #dee2e6;
}

.point {
  margin-top: 36px;
  margin-bottom: 10px;
}

.point tr td {
  text-align: left;
}

.point tr td a {
  font-size: 22px;
  line-height: 0;
  color: #000;
}

.point tr td .link_command {
  font-size: 16px;
  color: #000;
}

.point tr td .link_fuck {
  font-size: 16px;
  color: #000;
}

.point tr th {
  text-align: left;
}

#kriterii {
  background: #1e5380;
  position: relative;
  z-index: 5;
}

.breadcrumb {
  background: transparent;
}

.breadcrumb .breadcrumb-item {
  font-size: 14px;
  font-weight: 700;
}

.breadcrumb .breadcrumb-item::before {
  content: '';
}

.breadcrumb .breadcrumb-item::after {
  content: '-';
  margin-left: 15px;
}

.breadcrumb .breadcrumb-item:last-child::after {
  content: '';
  margin-left: 0;
}

.breadcrumb .breadcrumb-item a {
  font-size: 14px;
  font-weight: 700;
}

#btn_ready {
  display: inline-block;
  width: 250px;
  height: 47px;
  font-size: 20px;
  color: #fff;
  background-color: #343a40;
  border-radius: 5px;
  border: 1px solid #343a40;
}

.wrap_btn_res {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  font-weight: 700;
  align-items: baseline;
}

.wrap_btn_res .btn {
  padding: 5px 35px;
  font-family: 'Ubuntu', sans-serif;
}

.wrap_btn_res .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.wrap_btn_res .btn_ready {
  display: inline-block;
  width: 250px;
  height: 47px;
  font-size: 20px;
  color: #fff;
  background-color: #343a40;
  border-radius: 5px;
  border: 1px solid #343a40;
}

.wrap_btn_res .check_green {
  display: inline-block;
  width: 19px;
  height: 15px;
  background: url(../img/check.svg) center center / cover no-repeat;
  margin-right: 10px;
  margin-left: 45px;
  margin-top: 31px;
}

.input_new_point {
  width: 155px;
  height: 30px;
  text-indent: 5px;
}

.not_active {
  opacity: 0.5;
}

.danger {
  display: block;
  width: 100%;
  font-size: 16px;
  margin-top: 16px;
}

.navbar-nav {
  margin-left: 37px;
  margin-top: 11px;
}

.navbar-nav li {
  margin-right: 40px;
}

.navbar-nav li .active_link {
  display: inline-block;
}

.navbar-nav li .active_link::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 8px;
  background: #fff;
  margin-right: 12px;
  margin-bottom: 3px;
}

.navbar-nav .nav-item {
  text-align: right;
}

.navbar-nav .nav-item:first-child {
  margin-right: 40px;
}

.navbar-nav .nav-item a.nav-link {
  font-size: 20px;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 18px;
  height: 12px;
  background-image: url(../img/mobile_menu.svg) !important;
  border: none !important;
}

.navbar-toggler-icon::before {
  margin-top: 19px;
  content: 'Меню';
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-left: -16px;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:hover,
.navbar-toggler:active,
.navbar-toggler:focus {
  border: none !important;
  outline: none !important;
}

.navbar-collapse {
  margin-left: 64px;
}

.num_tab {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  text-align: center;
}

#table_block {
  display: none;
}

#table_block .tab_block {
  display: block;
  width: 91%;
  overflow-x: auto;
  position: fixed;
  top: 0;
  background: #e9ecef;
  margin-right: 15px;
}

#table_block .tab_block table thead tr th {
  color: #333333;
}

#table_block .tab_block table thead tr .num_first {
  min-width: 51px;
}

#table_block .tab_block table thead tr .command {
  min-width: 106px;
}

#table_block .tab_block table thead tr .value {
  min-width: 69px;
}

#table_block .tab_block table thead tr .fine {
  min-width: 77px;
}

#table_block .tab_block table thead tr .total_point {
  min-width: 73px;
}

#table_block .tab_block table thead tr .place {
  min-width: 50px;
}

#table_block .tab_block table thead tr .criterion {
  min-width: 459px;
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
  padding: 0 !important;
  border-bottom: none;
}

#table_block .tab_block table thead tr .criterion table {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

#table_block .tab_block table thead tr .criterion table tr {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

#table_block .tab_block table thead tr .criterion table tr th {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

#table_block .tab_block table thead tr .criterion table tr .k1 {
  min-width: 69px;
}

#table_block .tab_block table thead tr .criterion table tr .k2 {
  min-width: 76px;
}

#table_block .tab_block table thead tr .criterion table tr .k3 {
  max-width: 137px;
}

#table_block .tab_block table thead tr .criterion table tr .k4 {
  min-width: 147px;
}

#table_block .tab_block table thead tr .criterion table tr .k5 {
  min-width: 50px;
}

.criterion_glob {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
  padding: 0 !important;
}

.criterion_glob table {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

.criterion_glob table tr {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

.criterion_glob table tr th {
  background: #dee2e4 !important;
  border-color: #c0c4c6 !important;
}

#menu_nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: none;
  background: #9B331C;
  z-index: 10;
}

#menu_nav .wrap_first_row {
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  overflow: hidden;
}

#menu_nav .wrap_first_row .logo {
  display: inline-block;
  margin-left: 16px;
  margin-top: 8px;
}

#menu_nav .wrap_first_row #hide_menu {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../img/close.svg) center center / cover no-repeat;
  margin-right: 16px;
  margin-top: 8px;
}

tbody tr:first-child td {
  padding-top: 15px;
}

tbody tr td {
  vertical-align: middle;
}

tbody tr td a {
  text-decoration: underline;
}

.grey {
  color: #757575;
}

.table_block_origin {
  margin-top: 50px;
}

.link_vuz {
  color: #000;
}

@media screen and (max-width: 992px) {
  .thead-light tr {
    font-size: 12px;
  }

  tr td {
    font-size: 12px;
  }

  tr th {
    font-size: 12px;
  }

  .table thead th {
    font-size: 12px;
  }

  .point tr td a {
    font-size: 12px;
  }

  .point tr th {
    font-size: 12px;
  }

  .navbar-collapse {
    background: #9B331C;
    margin: 0 !important;
  }

  .navbar-nav {
    background: #9B331C;
    margin: 19px 0 0 0 !important;
  }

  .navbar-nav li {
    margin-right: 0;
  }

  .navbar-nav li a.nav-link {
    color: #fff !important;
    margin-right: 12px;
  }

  .navbar-nav .nav-item:first-child {
    margin-right: 0;
  }

  #table_block .tab_block table thead tr th {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .head_input {
    border-left: 8px solid #9b331c;
    padding-left: 10px;
  }

  .breadcrumb .breadcrumb-item a {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .form_block form .form-group {
    margin-bottom: 35px;
  }

  .head_input {
    font-size: 24px;
  }

  .breadcrumb .breadcrumb-item {
    margin-bottom: 10px;
  }

  .breadcrumb li[aria-current="page"] {
    padding-left: 0;
    margin-left: -8px;
  }

  .wrap_btn_res {
    margin-top: -18px;
  }

  .wrap_btn_res .check_green {
    margin-left: 0;
  }

  .nav-item {
    text-align: right;
  }
}